





import Vue from 'vue'
import MEditor, { MediumEditor } from 'medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/default.css'
import { IData, IMethods, IProps } from '@/types/components/rich-text-editor/textarea'

export default Vue.extend<IData, IMethods, unknown, IProps>({
  data() {
    return {
      mEditor: null,
    }
  },
  props: {
    elementId: {
      type: String,
      default: 'editor'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.initEditor(this.disabled)
  },
  beforeDestroy() {
    (this.mEditor as MediumEditor).unsubscribe('editableInput', this.onInput)
  },
  methods: {
    initEditor(disabled) {
      this.mEditor = new MEditor(`#${this.elementId}`, {
        disableEditing: disabled,
        toolbar: this.toolbarConfig(disabled),
        buttonLabels: 'fontawesome',
        anchor: {
          placeholderText: 'Type a link',
        },
        placeholder: {
          text: 'Type your message in HERE',
          hideOnClick: true
        },
        autoLink: true,
        targetBlank: true,
        anchorPreview: {
          hideDelay: 300
        },
      })
      this.mEditor && this.mEditor.subscribe('editableInput', this.onInput)
      this.initContent()
    },
    initContent() {
      this.mEditor && this.mEditor.setContent(this.value)
    },
    onInput() {
      this.$emit('input', (this.mEditor as MediumEditor).getContent())
    },
    toolbarConfig(disabled) {
      if (disabled) {
        return false
      } else {
        return {
          static: true,
          sticky: true,
          buttons: ['bold', 'italic', 'underline', 'strikethrough', 'anchor', 'orderedlist', 'unorderedlist', 'indent', 'outdent', 'h2', 'h3', 'quote'],
        }
      }
    }
  },
})
