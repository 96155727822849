













import Vue from 'vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import RichTextEditor from '@components/rich-text-editor/textarea.vue'
import { fetchDealerDisclaimer, saveDealerDisclaimer } from '@api/addy-plus/accounts'

export default Vue.extend({
  components: {
    Breadcrumb,
    RichTextEditor,
  },
  data() {
    return {
      content: '' as string,
      isLoading: false as boolean,
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
  },
  created() {
    this.fetchDisclaimer()
  },
  methods: {
    fetchDisclaimer() {
      fetchDealerDisclaimer(this.accountId).then((res) => {
        if (res.success && res.data) {
          this.content = res.data

          this.$nextTick(() => {
            // @ts-ignore
            this.$refs.disclaimerEditor.initContent()
          })
        }
      })
    },
    onSave() {
      this.isLoading = true
      saveDealerDisclaimer(this.accountId, this.content).then((res) => {
        this.isLoading = false
        if (!res.success) return
        this.$router.go(-1)
      })
    },
  },
})
